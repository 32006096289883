
.swiper {
    width: 100%;
    height: 35%;
    /* margin-top: 50; */
    /* margin-left: auto;
    margin-right: auto; */
  }
.swiper-wrapper {
    width: 100%;
     
     margin:0;
     padding: 0;
    /* margin-top: 50; */
    /* margin-left: auto;
    margin-right: auto; */
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: calc((75% - 30px) / 2) !important;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }